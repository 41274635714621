import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import HeaderBanner from "../components/headerbanner"
import SEOHeader from "../components/seo-header"

const PrivacyPolicy = () => {
  return (
    <div>
      <SEOHeader
        title="GoFloaters Privacy Policy | Terms & Conditions 2020"
        description="safeguard and disclose information that results from your use of
        our Service."
        socialURL="https://assets.gofloaters.com/socialimage/coworking-spaces-in-bengaluru.jpg"
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <HeaderBanner>
          <h1>Privacy Policy</h1>
        </HeaderBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <p>
                <strong>1. Introduction</strong>
              </p>
              <p>
                Welcome to SMBSure Business Solutions Private Limited.
                <br />
                SMBSure Business Solutions Private Limited (&ldquo;us&rdquo;,
                &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates GoFloaters app
                and gofloaters.com (hereinafter referred to as
                &ldquo;Service&rdquo;).
                <br />
                Our Privacy Policy governs your visit to GoFloaters app and
                gofloaters.com, and explains how we collect, safeguard and
                disclose information that results from your use of our Service.
              </p>
              <p>
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
                <br />
                Our Terms and Conditions (&ldquo;Terms&rdquo;) govern all use of
                our Service and together with the Privacy Policy constitutes
                your agreement with us (&ldquo;agreement&rdquo;).
              </p>
              <br />
              <p>
                <strong>2. Definitions</strong>
              </p>
              <p>
                SERVICE means the GoFloaters app and gofloaters.com website
                operated by SMBSure Business Solutions Private Limited.
              </p>
              <p>
                PERSONAL DATA means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession).
              </p>
              <p>
                USAGE DATA is data collected automatically either generated by
                the use of Service or from Service infrastructure itself (for
                example, the duration of a page visit).
              </p>
              <p>
                COOKIES are small files stored on your device (computer or
                mobile device).
              </p>
              <p>
                DATA CONTROLLER means a natural or legal person who (either
                alone or jointly or in common with other persons) determines the
                purposes for which and the manner in which any personal data
                are, or are to be, processed. For the purpose of this Privacy
                Policy, we are a Data Controller of your data.
              </p>
              <p>
                DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or
                legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                in order to process your data more effectively.
              </p>
              <p>
                DATA SUBJECT is any living individual who is the subject of
                Personal Data.
              </p>
              <p>
                THE USER is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>{" "}
              <br />
              <p>
                <strong>3. Information Collection and Use</strong>
              </p>
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>{" "}
              <br />
              <p>
                <strong>4. Types of Data Collected</strong>
              </p>
              <p>
                Personal Data
                <br />
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (&ldquo;Personal Data&rdquo;).
                Personally identifiable information may include, but is not
                limited to:
                <br />
                0.1. Email address
                <br />
                0.2. First name and last name
                <br />
                0.3. Phone number
                <br />
                0.4. Address, Country, State, Province, ZIP/Postal code, City
                <br />
                0.5. Cookies and Usage Data
                <br />
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link.
              </p>
              <p>
                Usage Data
                <br />
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through
                any device (&ldquo;Usage Data&rdquo;).
              </p>
              <p>
                This Usage Data may include information such as your
                computer&rsquo;s Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that you
                visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.
                <br />
                When you access Service with a device, this Usage Data may
                include information such as the type of device you use, your
                device unique ID, the IP address of your device, your device
                operating system, the type of Internet browser you use, unique
                device identifiers and other diagnostic data.
              </p>
              <p>
                Location Data
                <br />
                We may use and store information about your location if you give
                us permission to do so (&ldquo;Location Data&rdquo;). We use
                this data to provide features of our Service, to improve and
                customize our Service.
                <br />
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
              </p>
              <p>
                Tracking Cookies Data
                <br />
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
                <br />
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
                <br />
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
                <br />
                Examples of Cookies we use:
                <br />
                0.1. Session Cookies: We use Session Cookies to operate our
                Service.
                <br />
                0.2. Preference Cookies: We use Preference Cookies to remember
                your preferences and various settings.
                <br />
                0.3. Security Cookies: We use Security Cookies for security
                purposes.
                <br />
                0.4. Advertising Cookies: Advertising Cookies are used to serve
                you with advertisements that may be relevant to you and your
                interests.
                <br />
                Other Data
              </p>
              <p>
                While using our Service, we may also collect the following
                information: sex, age, date of birth, place of birth, passport
                details, citizenship, registration at place of residence and
                actual address, telephone number (work, mobile), details of
                documents on education, qualification, professional training,
                employment agreements,
                <a href="https://policymaker.io/non-disclosure-agreement/">
                  non-disclosure agreements
                </a>
                , information on bonuses and compensation, information on
                marital status, family members, social security (or other
                taxpayer identification) number, office location and other data.
              </p>{" "}
              <br />
              <p>
                <strong>5. Use of Data</strong>
              </p>
              <p>
                SMBSure Business Solutions Private Limited uses the collected
                data for various purposes:
                <br />
                0.1. to provide and maintain our Service;
                <br />
                0.2. to notify you about changes to our Service;
                <br />
                0.3. to allow you to participate in interactive features of our
                Service when you choose to do so;
                <br />
                0.4. to provide customer support;
                <br />
                0.5. to gather analysis or valuable information so that we can
                improve our Service;
                <br />
                0.6. to monitor the usage of our Service;
                <br />
                0.7. to detect, prevent and address technical issues;
                <br />
                0.8. to fulfil any other purpose for which you provide it;
                <br />
                0.9. to carry out our obligations and enforce our rights arising
                from any contracts entered into between you and us, including
                for billing and collection;
                <br />
                0.10. to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
                <br />
                0.11. to provide you with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless you have opted not to receive such
                information;
                <br />
                0.12. in any other way we may describe when you provide the
                information;
                <br />
                0.13. for any other purpose with your consent.
              </p>{" "}
              <br />
              <p>
                <strong>6. Retention of Data</strong>
              </p>
              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br />
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>{" "}
              <br />
              <p>
                <strong>7. Transfer of Data</strong>
              </p>
              <p>
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
                <br />
                If you are located outside India and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to India and process it there.
                <br />
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
                <br />
                SMBSure Business Solutions Private Limited will take all the
                steps reasonably necessary to ensure that your data is treated
                securely and in accordance with this Privacy Policy and no
                transfer of your Personal Data will take place to an
                organisation or a country unless there are adequate controls in
                place including the security of your data and other personal
                information.
              </p>{" "}
              <br />
              <p>
                <strong>8. Disclosure of Data</strong>
              </p>
              <p>
                We may disclose personal information that we collect, or you
                provide:
                <br />
                0.1. Disclosure for Law Enforcement.
                <br />
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
                <br />
                0.2. Business Transaction.
                <br />
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
                <br />
                0.3. Other cases. We may disclose your information also:
                <br />
                0.3.1. to our subsidiaries and affiliates;
                <br />
                0.3.2. to contractors, service providers, and other third
                parties we use to support our business;
                <br />
                0.3.3. to fulfill the purpose for which you provide it;
                <br />
                0.3.4. for the purpose of including your company&rsquo;s logo on
                our website;
                <br />
                0.3.5. for any other purpose disclosed by us when you provide
                the information;
                <br />
                0.3.6. with your consent in any other cases;
                <br />
                0.3.7. if we believe disclosure is necessary or appropriate to
                protect the rights, property, or safety of the Company, our
                customers, or others.
              </p>{" "}
              <br />
              <p>
                <strong>9. Security of Data</strong>
              </p>
              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>{" "}
              <br />
              <p>
                <strong>10. User account and data deletion</strong>
                <br />
                <br />
                Users can request their account and data to be deleted from
                GoFloaters either by writing to{" "}
                <a href="mailto:support@gofloaters.com">
                  support@gofloaters.com
                </a>{" "}
                or by using the &ldquo;Request Account Deletion&rdquo; option in
                the &ldquo;View Account&rdquo; section of the &ldquo;My
                Profile&rdquo; page. Account and data deletion request would be
                processed by GoFloaters within 15 business days.{" "}
              </p>{" "}
              <br />
              <p>
                <strong>11. Service Providers</strong>
              </p>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“Service Providers”), provide Service on
                our behalf, perform Service-related services or assist us in
                analysing how our Service is used.
                <br />
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>{" "}
              <br />
              <p>
                <strong>12. Analytics</strong>
              </p>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>{" "}
              <br />
              <p>
                <strong>13. CI/CD tools</strong>
              </p>
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>{" "}
              <br />
              <p>
                <strong>14. Behavioral Remarketing</strong>
              </p>
              <p>
                We may use remarketing services to advertise on third party
                websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimise and serve
                ads based on your past visits to our Service.
              </p>{" "}
              <br />
              <p>
                <strong>15. Payments</strong>
              </p>
              <p>
                We may provide paid products and/or services within Service. In
                that case, we use third-party services for payment processing
                (e.g. payment processors).
              </p>
              <p>
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>{" "}
              <br />
              <p>
                <strong>16. Links to Other Sites</strong>
              </p>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party&rsquo;s site. We strongly advise
                you to review the Privacy Policy of every site you visit.
                <br />
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>{" "}
              <br />
              <p>
                <strong>17. Children’s Privacy</strong>
              </p>
              <p>
                Our Services are not intended for use by children under the age
                of 18 (&ldquo;Child&rdquo; or &ldquo;Children&rdquo;).
              </p>
              <p>
                We do not knowingly collect personally identifiable information
                from Children under 18. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>{" "}
              <br />
              <p>
                <strong>18. Changes to This Privacy Policy</strong>
              </p>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                &ldquo;effective date&rdquo; at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>{" "}
              <br />
              <p>
                <strong>19. Contact Us</strong>
              </p>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us by email: support@gofloaters.com.{" "}
              </p>{" "}
              <br /> <br />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default PrivacyPolicy
